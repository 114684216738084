.cards {
  padding: 4rem;
  background: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* height: 100vh; */
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}


.weather {
  padding: 2rem; /* Adjust padding as needed */
  background: whitesmoke;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Create grid columns with a minimum width of 300px */
  grid-gap: 2rem; /* Adjust gap between grid items */
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure a minimum height of 100% of the viewport height */
  width: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}



h1 {
  text-align: center;
  color: whitesmoke;
  font-size: 60px;
  margin-top: 20px; /* Adjust as needed */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1.5);
}

h3 {
  text-align: center;
  color: whitesmoke;
  font-size: 20px;
  margin-top: 20px; /* Adjust as needed */
  opacity: 0; /* Initial opacity */
  transition: opacity 0.3s ease-in-out; /* Smooth transition for opacity */
}

h3:hover {
  color: whitesmoke; /* Change to h1 color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1.5); /* Change to h1 text-shadow */
  opacity: 1; /* Change opacity on hover */
}


.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 100vw;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
  width: 100%;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: whitesmoke;
  background-color: rebeccapurple;
  box-sizing: border-box;
  border-radius: 10px;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}
