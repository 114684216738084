.tool-container {
    background: #f2f2f2; /* Light grey background color */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
    
  
  .tool-container > h1 {
    color: whitesmoke;
    font-size: 100px;
    margin-top: -100px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1.5);
  }
  
  .tool-container > p {
    margin-top: 8px;
    color: whitesmoke;
    font-size: 32px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1.5);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .tool-btns {
    margin-top: 32px;
  }
  
  .tool-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .tool-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .tool-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .tool-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }


    .input-label {
      margin-bottom: 10px; /* Adjust as needed */
      margin-bottom: 10px; /* Adjust as needed */
    }

    .forecast-list {
      display: flex; /* Set display to flex */
      flex-wrap: wrap; /* Allow items to wrap to the next line */
    }
    
    .forecast-item {
      width: 250px; /* Set a fixed width for each forecast item */
      border: 1px solid #ccc;
      padding: 20px;
      background-color: #f9f9f9;
      margin-right: 20px; /* Add margin to create space between items */
      margin-bottom: 20px; /* Add margin to create space between lines */
    }
    
    
    
  }
  