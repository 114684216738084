/* Doc.css */
.doc-image {
    max-width: 80%; /* 90% of the viewport width */
    max-height: 100vh; /* Max height without exceeding original size */
    object-fit: cover; /*Maintain aspect ratio and shrink to fit*/
    display: block;
    margin: auto; /* Center the image */
    border-radius: 1500px; /* Rounded corners */
    box-shadow: 0 0 100px rgba(0, 0, 0, 2); /* Drop shadow */
  }
  