.footer-container {
  background-color: #242424;
  padding: 1rem 0; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #696969;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  font-size: 18px;
  /* margin-bottom: 16px; */
  /* border: 1px solid red; */
}

.footer-links {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items:center;
  margin: 0px;
  width: 100%;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 10px;
  color: #696969;
}

.footer-link-items a {
  color: #696969;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: rebeccapurple;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #696969;
  font-size: 60px;
  /* border: 1px solid red; */
  margin: 10px;
}


.social-media {
  max-width: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 10%;
  max-width: 100%;
  /* margin: 20px auto 0 auto; */
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%

}

.social-logo {
  color: #696969;
  justify-self: center;
  /* margin-left: 20px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
}

.website-rights {
  color: #696969;
  /* margin-bottom: 10px; */
}

@media screen and (max-width: 100%) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 100%) {
}